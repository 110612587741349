var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"First Name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"First Name","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"First Name"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Last Name","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"Last Name"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"First Name"}},[_c('validation-provider',{attrs:{"rules":"arabicOnly","name":"first Name","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"Arabic First Name"},model:{value:(_vm.user.firstNameArabic),callback:function ($$v) {_vm.$set(_vm.user, "firstNameArabic", $$v)},expression:"user.firstNameArabic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Arabic Last Name"}},[_c('validation-provider',{attrs:{"rules":"arabicOnly","name":"Last Name","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"Arabic Last Name"},model:{value:(_vm.user.lastNameArabic),callback:function ($$v) {_vm.$set(_vm.user, "lastNameArabic", $$v)},expression:"user.lastNameArabic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Email Address"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email Address","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","state":errors.length ? false : null,"placeholder":"Email Address"},model:{value:(_vm.user.emailAddress),callback:function ($$v) {_vm.$set(_vm.user, "emailAddress", $$v)},expression:"user.emailAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Phone number"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Phone Number","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"placeholder":"Phone Number"},model:{value:(_vm.user.cellPhone),callback:function ($$v) {_vm.$set(_vm.user, "cellPhone", $$v)},expression:"user.cellPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Role"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Role","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.roles,"placeholder":"Select role"},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.save}},[_c('span',[_vm._v("Save")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }