<template>
  <validation-observer ref="validationObserver">
    <b-card-form>
      <!-- First Name and Last Name -->
      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="First Name">
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="First Name"
                mode="passive"
            >
              <b-form-input
                  v-model="user.firstName"
                  :state="errors.length ? false : null"
                  placeholder="First Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Last Name">
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Last Name"
                mode="passive"
            >
              <b-form-input
                  v-model="user.lastName"
                  :state="errors.length ? false : null"
                  placeholder="Last Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

       <!-- Arabic First Name and Last Name -->
       <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="First Name">
            <validation-provider v-slot="{ errors }" rules="arabicOnly" name="first Name" mode="passive">

              <b-form-input
                  v-model="user.firstNameArabic"
                  :state="errors.length ? false : null"
                  placeholder="Arabic First Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Arabic Last Name">
            <validation-provider v-slot="{ errors }" rules="arabicOnly" name="Last Name" mode="passive">

              <b-form-input
                  v-model="user.lastNameArabic"
                  :state="errors.length ? false : null"
                  placeholder="Arabic Last Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Email and Phone -->
      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Email Address">
            <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Email Address"
                mode="passive"
            >
              <b-form-input
                  type="email"
                  v-model="user.emailAddress"
                  :state="errors.length ? false : null"
                  placeholder="Email Address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>

        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Phone number">
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Phone Number"
                mode="passive"
            >
              <b-form-input
                  v-model="user.cellPhone"
                  :state="errors.length ? false : null"
                  placeholder="Phone Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
      </b-row>

      <!-- Role -->
      <b-row>
        <b-col md="6" xl="6" class="mb-1">
          <b-form-group label="Role">
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Role"
                mode="passive"
            >
              <v-select
                  v-model="user.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="roles"
                  placeholder="Select role"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save"
          >
            <span>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import {
  BFormInput, BRow, BCol, BFormGroup, BButton
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import {ValidationProvider, ValidationObserver,extend} from 'vee-validate';
import {
  required,
  email
} from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';


  // Extend VeeValidate with a custom rule for Arabic text validation
  extend('arabicOnly', {
  validate(value) {
    // Regular expression to match Arabic characters
    const arabicRegex = /^[\u0600-\u06FF\s]+$/;
    return arabicRegex.test(value);
  },
  message: 'The {_field_} field must contain only Arabic characters.'
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      user: {
        firstName: null,
        lastName: null,
        firstNameArabic: null,
        lastNameArabic : null,
        emailAddress: null,
        cellPhone: null,
        role: null
      },
      roles: [],
      required,
      email
    }
  },

  methods: {
    async save() {
      const isValid = await this.$refs.validationObserver.validate();

      // Validation Failed
      if (!isValid) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Check fields validation!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      // Validation Passed
      try {
        await this.$http.post('users', this.user);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'User has been added',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });

        this.$router.push({name: 'UsersIndex'});
      } catch (error) {
      }
    },

  },

  async created() {
    try {
      const response = await this.$http.get('lookups/roles');
      this.roles = response.data;
    } catch (error) {
    }
  }
}
</script>
